import React from 'react'
import { Container } from 'react-bootstrap'

export default ({ children }) => (
  <section className="bg-gradient01 py-3">
    <Container className="d-flex flex-lg-row flex-column justify-content-center align-items-center">
      <div className="p-3">
        ハイローオーストラリア
        <br />
        口座開設
      </div>
      <a
        className="btn is-size-lg-4 is-size-2"
        href="https://highlow.com/register?a_aid=55828e847e269"
        rel="nofollow"
      >
        無料アカウント開設
      </a>
    </Container>
  </section>
)
