import Footer from '@components/footer'
import '@scss/gatstrap.scss'
import React from 'react'
import { Helmet } from 'react-helmet'

export default function Layout({ children }) {
  return (
    <>
      <Helmet
        meta={[
          {
            name: 'google-site-verification',
            content: 't5oEGfGSEWacfye53VaeBZp8dvH88UiqgGryPzf-s_0',
          },
        ]}
      />
      <div className="wrapper bg-dark">
        {children}
        <Footer />
      </div>
    </>
  )
}
