import Layout from '@container/layout'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.contentfulBlogPost
  console.info(data.contentfulBlogPost.seoTitle)
  const siteTitle =
    data.contentfulBlogPost.seoTitle === null
      ? data.site.siteMetadata.title
      : data.contentfulBlogPost.seoTitle
  const siteDescription =
    data.contentfulBlogPost.seoDescription === null
      ? data.site.siteMetadata.description
      : data.contentfulBlogPost.seoDescription
  const siteKeyword =
    data.contentfulBlogPost.seoKeyword !== null
      ? data.contentfulBlogPost.seoKeyword
      : ''

  return (
    <Layout location={location}>
      <Helmet title={`${post.title} | ${siteTitle}`} />
      <header className="py-2">
        <Container>
          <div className="d-flex flex-column flex-md-row  justify-content-between align-items-center pt-3">
            <h1 className="text-white is-size-6 mb-3 mb-md-0">{post.title}</h1>
            <Link to="/blog" className="btn btn-0info d-flex mb-0">
              ブログ一覧
            </Link>
          </div>
        </Container>
      </header>
      <Container>
        <div className="py-2">
          <Img alt="" fluid={post.heroImage.fluid} />
        </div>
      </Container>
      <Container>
        <Row>
          <Col>
            <div
              className="text-white"
              dangerouslySetInnerHTML={{
                __html: post.body.childMarkdownRemark.html,
              }}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      seoTitle
      seoDescription
      seoKeyword
    }
  }
`
