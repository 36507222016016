import CtaArea from '@components/cta-area'
import React from 'react'
import { Col, Container } from 'react-bootstrap'

export default ({ children }) => (
  <>
    <CtaArea />
    <footer className="footer">
      <Container>
        <Col lg="9" className="bg-white text-dark mx-auto">
          <div className="text-center p-3 mb-4">
            <p className="is-size-5 font-weight-bold">
              【無料攻略ツール配布中！】
            </p>
            <p>
              メール登録だけで管理人も使っているハイロー勝率アップが見込める攻略ツールのプレゼント特典！
            </p>
            <a
              className="text-primary is-size-5 font-weight-bold"
              href="https://drive.google.com/open?id=1LX6tojgUNjqOB2LiU6WEoeS_SDgQ4V64fh-5zW3utpI"
              target="_blank"
            >
              ＞＞メールご登録で無料攻略ツールをもらうにはこちらから
            </a>
            <p className="mt-3">
              *メルマガサービスから配信されます。万が一迷惑メールに振分けられる場合はご設定をお願いいたします。
            </p>
            <p>
              また無料メルマガ「メールトレードニュース」にも代理登録されます。メール文末からいつでも解除することができます。
            </p>
            <p>
              ご入力頂きましたメールアドレスへは、上述のメルマガを配信する他、お客様のご連絡の目的でのみ使用します。
            </p>
          </div>
        </Col>
      </Container>
      <Container className="text-white">
        <p className="text-center">
          Highlow Markets Pty.
          Ltd.は監督官庁ASICによって、登録認可ならびに厳正な規制と管理のもと、顧客保護を徹底しています。
        </p>
        <p>【ASIC認証番号 No.364264 】</p>
        <p>
          取引手数料（費用）について】お客様が利用される口座の口座開設費、口座保有費、口座維持費など、一切手数料や費用はかかりません。その他も取引に際しての費用は無料です。
        </p>
        <p>
          【取引リスクに関する注意】お取引にはお客様ご自身の元本損失リスクがございます。お取引内容を正確に精査し、ご自身の責任の範囲内において慎重にお取り組みください。
        </p>
        <div className="pb-3">
          <u>
            <a className="text-dark" href="/rule.html">
              <span>特定商取引に関する表示</span>
            </a>
          </u>
        </div>
        <p>Copyright 2019 © HighLow All Rights Reserved.</p>
      </Container>
    </footer>
  </>
)
